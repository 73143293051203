import { transformContent } from '@/utils'
export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.slug', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  return [
    {
      condition: get('media.cover').length,
      component: 'Section',
      props: {
        width: 'full',
        condition: get('media.cover').length,

        id: 'section-hero',
        class: 'section-hero',
        style: 'padding:0;',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            data: get('media.cover'),
            props: {
              aspectRatio: '16/9',
              class: 'hero__slider',
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('content'),
      props: {
        width: 'medium',
        id: 'section-info',
      },
      blocks: {
        default: [
          {
            component: 'Text',

            data: {
              content: transformContent(get('content')),
            },
          },
        ],
      },
    },
  ]
}
