import { transformContent } from '@/utils'

export default function () {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.slug', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  return [
    {
      component: 'Section',
      condition: get('customFields.show-page-title'),
      props: {
        id: 'section-title'
      },
      blocks: {
        default: {
          component: 'Text',
          props: {
            textAlign: 'center'
          },
          data: {
            content: `<h1 style="margin: 0; font-weight: 500">${get('title')}</h1>`
          }
        }
      }
    },
    {
      component: 'Section',
      condition:
        get('media.cover.length') ||
        (get('customFields.embed.url') && get('customFields.embed.show-as-cover')),
      props: {
        width: 'large',
        id: 'section-hero',
        style: 'padding: 0;',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            condition: !get('customFields.embed.show-as-cover'),
            data: get('media.cover'),
            props: {
              aspectRatio: '16/9',
              class: 'hero__slider',
            },
          },
          {
            component: 'Embed',
            condition: get('customFields.embed.url') && get('customFields.embed.show-as-cover'),
            props: {},
            data: {
              src: get('customFields.embed.url'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: transformContent(get('content')),
      props: {
        width: 'small',
        id: 'section-info',
      },
      blocks: {
        default: [
          {
            component: 'Text',

            data: {
              content: transformContent(get('content')),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('customFields.embed.url') && !get('customFields.embed.show-as-cover'),
      props: {
        width: 'medium',
      },
      blocks: {
        default: [
          {
            component: 'Embed',
            props: {},
            data: {
              src: get('customFields.embed.url'),
            },
          },
        ],
      },
    },

    ...(get('customFields.additional-sections-1') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          captionPlacement: i['image-caption-placement'] || 'outside'
        },
      }
    }),

    {
      component: 'Section',
      condition: get('customFields.icons.0.icon.url') || get('customFields.icons.0.content'),
      props: {
        width: 'medium',

        theme: get('customFields.icons-settings.theme'),
        style: !get('customFields.icons-settings.theme') ? 'padding-top:0;' : '',
      },
      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              columns: parseInt(get('customFields.icons-settings.columns')) || 4,
              iconBackgroundColor: get('customFields.icons-settings.show-icon-background-color')
                ? get('customFields.icons-settings.icon-background-color')
                : 'inherit',
              borderTheme: get('customFields.icons-settings.border-theme'),
              placement: get('customFields.icons-settings.placement'),
              iconBorder: get('customFields.icons-settings.icon-border'),
              boldContent: true,
            },
            data: {
              content: get('customFields.icons'),
            },
            variant: get('customFields.icons-settings.variant') || 'underline',
          },
        ],
      },
    },

    ...(get('customFields.additional-sections-2') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          customColor: get('item.customFields.custom-color', 'Root'),
          captionPlacement: i['image-caption-placement'] || 'outside'
        },
      }
    }),

    {
      component: 'Section',
      condition: get('customFields.page-settings.display-map'),
      props: {
        width: 'medium',
        class: 'section-map',
      },
      blocks: {
        default: [
          {
            component: 'Map',
            props: {
              aspectRatio: '4/3',
              mapOptions: {
                ...this.mapOptions,
              },
            },
            data: {
              ...get('address', 'Root.item'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition:
        get('customFields.cta.content', 'Root.item') || get('customFields.cta.button', 'Root.item'),
      props: {
        class: 'cta-bottom ',
        style: 'padding-block: 3.5rem;',
        theme: get('customFields.cta.invert') ? 'white' : 'primary'
      },

      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              flexDirection: 'column',
              alignItems: 'center',
              class: 'cta-bottom__content',
            },

            blocks: {
              default: [
                {
                  component: 'Text',
                  data: {
                    content: get('customFields.cta.content') ?? get('customFields.cta.content', 'Root.item'),
                  },
                  props: {
                    textAlign: 'center',
                  },
                },
                {
                  component: 'Button',
                  data: {
                    label: get('customFields.cta.button') ?? get('customFields.cta.button', 'Root.item'),
                    tag: 'router-link',
                    to: get('customFields.cta.link') ?? get('customFields.cta.link', 'Root.item') ?? { name: 'Contact' },
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
