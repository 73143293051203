import { transformContent } from '@/utils'
export default function () {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.slug', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  return [
    {
      component: 'Section',
      condition: get('customFields.show-page-title'),
      props: {
        id: 'section-title'
      },
      blocks: {
        default: {
          component: 'Text',
          props: {
            textAlign: 'center'
          },
          data: {
            content: `<h1 style="margin: 0; font-weight: 500">${get('title')}</h1>`
          }
        }
      }
    },
    {
      condition: get('media.cover').length,
      component: 'Section',
      props: {
        width: 'full',
        condition: get('media.cover').length,

        id: 'section-hero',

        style: 'padding:0;',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            data: get('media.cover'),
            props: {
              aspectRatio: '16/9',
              class: 'hero__slider',
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('content'),
      props: {
        width: 'small',
        id: 'section-info',
        style: 'padding-top: 0;'
      },
      blocks: {
        default: {
          component: 'Text',
          props: { textAlign: 'center', style: 'max-width: 70ch; margin-inline: auto;' },
          data: {
            content: transformContent(get('content')),
          },
        },
      },
    },

    {
      component: 'Section',
      condition: get('customFields.icons.0.icon.url') || get('customFields.icons.0.content'),
      props: {
        width: 'medium',

        theme: get('customFields.icons-settings.theme'),
        style: !get('customFields.icons-settings.theme') ? 'padding-top:0;' : '',
      },
      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              columns: parseInt(get('customFields.icons-settings.columns')) || 4,
              iconBackgroundColor: get('customFields.icons-settings.show-icon-background-color')
                ? get('customFields.icons-settings.icon-background-color')
                : 'inherit',
              borderTheme: get('customFields.icons-settings.border-theme'),
              placement: get('customFields.icons-settings.placement'),
              iconBorder: get('customFields.icons-settings.icon-border'),
              boldContent: true,
            },
            data: {
              content: get('customFields.icons'),
            },
            variant: get('customFields.icons-settings.variant') || 'underline',
          },
        ],
      },
    },

    {
      component: 'Section',
      condition:
        get('residentials', 'Root.item').length && get('customFields.page-settings.display-plans'),
      props: {
        width: 'medium',
      },
      blocks: {
        default: {
          component: 'Gallery',
          props: {
            class: 'plans',
            columns: 1,
            gap: '2rem',
          },
          variant: 'grid',
          data: {
            images: (get('residentials', 'Root.item') || [])
              .map(item => {
                return item.media.floorplan.map(i => {
                  if (!i.description && i.name) i.description = i.name
                  return i
                })
              })
              .flat(),
          },
        },
      },
    },
    ...(get('customFields.additional-sections-1') || []).map(i => {
      return {
        component: 'ImagesTextSection',
        condition: i?.content || i?.images?.length,
        data: {
          content: i,
        },
        condition: i,
        props: {
          layout: i.layout,
          width: i.width || 'medium',
          theme: i.theme || 'none',
          captionPlacement: i['image-caption-placement'] || 'outside'
        },
      }
    }),

    {
      component: 'Section',
      condition:
        get('customFields.cta.content', 'Root.item') || get('customFields.cta.button', 'Root.item'),
      props: {
        class: 'cta-bottom ',
        style: 'padding-block: 3.5rem;',
        theme: get('customFields.cta.invert') ? 'white' : 'primary'
      },

      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              flexDirection: 'column',
              alignItems: 'center',
              class: 'cta-bottom__content',
            },

            blocks: {
              default: [
                {
                  component: 'Text',
                  data: {
                    content: get('customFields.cta.content') ?? get('customFields.cta.content', 'Root.item'),
                  },
                  props: {
                    textAlign: 'center',
                  },
                },
                {
                  component: 'Button',
                  data: {
                    label: get('customFields.cta.button') || get('customFields.cta.button', 'Root.item'),
                    to: { name: 'Contact' },

                    tag: 'router-link',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
